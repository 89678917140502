<template>
	<div>
		<Header class="page-anim1" />

		<section>
			<div class="container contact">
				<div
					class="title mt-3-desktop mt-2-mobile mt-3-tablet color-primary animated fadeInLeft"
				>
					Contato
				</div>

				<transition enter-active-class="animated zoomIn">
					<div class="form mt-3-desktop">
						<div class="input-item">
							<span>Nome *</span>
							<input type="text" v-model="form.name" />
						</div>
						<div class="input-item">
							<span>E-mail *</span>
							<input type="text" v-model="form.email" />
						</div>
						<div class="input-item">
							<span>Telefone</span>
							<input type="text" v-model="form.phone" />
						</div>
						<div class="input-item">
							<span>Mensagem *</span>
							<textarea v-model="form.message"></textarea>
						</div>

						<transition enter-active-class="animated fadeIn">
							<p v-show="warningMessage" class="warning">
								{{ warningMessage }}
							</p>
						</transition>

						<transition enter-active-class="animated fadeIn">
							<p v-show="formSent" class="success">
								Mensagem enviada. Obrigado pelo contato!
							</p>
						</transition>

						<div class="btn" @click="sendMessage">
							<span v-show="!loading">Enviar</span>
							<div v-show="loading" class="loading2 white"></div>
						</div>
					</div>
				</transition>
			</div>
		</section>
	</div>
</template>
<script>
	import http from "@/http";
	import authentication from "../util/authentication";
	import validators from "../util/validators";
	import Header from "../components/Header";
	import PostCard from "../components/PostCard";
	import MapMarkerRadius from "vue-material-design-icons/MapMarkerRadius.vue";

	export default {
		name: "Home",
		components: {
			Header,
			PostCard,
			MapMarkerRadius,
		},
		data() {
			return {
				loading: false,
				form: {
					name: "",
					email: "",
					phone: "",
					message: "",
				},
				warningMessage: "",
				formSent: false,
			};
		},
		created() {
			if (authentication.isLoggedIn()) {
				this.form = { ...this.form, ...authentication.getUser().info };
			}
		},
		methods: {
			sendMessage() {
				if (this.form.name.length < 3) {
					this.warningMessage = "Por favor, informe o seu nome";
					return;
				}
				if (!validators.validateEmail(this.form.email)) {
					this.warningMessage = "Por favor, informe um e-mail válido";
					return;
				}
				if (this.form.message.length < 5) {
					this.warningMessage = "Por favor, digite a sua mensagem";
					return;
				}

				this.loading = true;
				this.warningMessage = "";

				http
					.post("contact/send", this.form)
					.then(() => {
						this.form = {
							name: "",
							email: "",
							phone: "",
							message: "",
						};
						this.$swal({
							icon: "success",
							title: "Contato recebido!",
							text: "Recebemos o seu contato. Obrigado!",
						});
					})
					.catch((err) => {
						this.warningMessage =
							"Algo deu errado. Por favor, tente novamente em instantes.";
						console.log(err);
					})
					.finally(() => {
						this.loading = false;
					});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.container.contact {
		max-width: 620px;
	}

	textarea {
		position: relative;
		width: 100%;
		height: 80px;
		// box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
		border-bottom: 1px solid #999;
		resize: none;
		margin-top: 15px;
	}

	.form {
		position: relative;
		width: 100%;
		padding: 10px 20px 20px 20px;
		box-shadow: 0 35px 50px 0 rgba(153, 16, 16, 0.144);
		margin: 25px auto 45px auto;
		border-radius: 12px;
	}

	.success {
		font-size: 1.2em;
		color: rgb(59, 175, 59);
		text-align: center;
		margin: 20px 0;
	}

	@media screen and (min-width: 1160px) {
	}
</style>
