<template>
  <div @click="$router.push(`/artigo/${slug}`)" class="card">
    <div class="img-holder" :style="`background-image:url(${image})`"></div>
    <div class="content">
      <h1>{{ title }}</h1>
      <p class="desc">{{ summary }}</p>
      <div class="bottom-holder">
        <router-link :to="`/artigo/${slug}`">Continuar lendo</router-link>
        <div class="timestamp">{{ timestamp | moment("DD/MM/YYYY") }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import Thumb from "vue-material-design-icons/ThumbUpOutline.vue";

export default {
  components: {
    Thumb,
  },
  props: {
    id: {
      type: String,
      default: "--",
    },
    slug: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "--",
    },
    summary: {
      type: String,
      default: "--",
    },
    image: {
      type: String,
      default: "",
    },
    timestamp: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  position: relative;
  width: 100%;
  height: 140px;
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: flex-start;
  border-radius: 6px;
  cursor: pointer;
  .img-holder {
    position: relative;
    width: 120px;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 6px;
  }
  .content {
    position: relative;
    width: calc(100% - 120px);
    padding: 12px;
    h1 {
      font-family: fontLight;
      font-size: 1.2em;
      color: var(--dark4);
    }
    p.desc {
      font-family: fontLight;
      font-size: 1.05em;
      color: var(--dark5);
      margin-top: 6px;
      line-height: 1.25em;
      height: 3.75em;
      overflow: hidden;
      margin-right: 10px;
    }
  }
  .bottom-holder {
    position: absolute;
    width: calc(100% - 30px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 12px;
    a {
      font-size: 1.05em;
      color: var(--primary);
    }
    .timestamp {
      position: relative;
      font-style: italic;
    }
  }
}
</style>
